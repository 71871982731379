<template>
  <div class="tw-px-6 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-items-start tw-gap-8">
    <v-treeview
      class="client-features-recursive__treeview"
      :class="{ 'client-features-recursive__treeview--disabled': featureParent.childFeatures.length === 0 }"
      v-for="(featureParent, index) in modelFeatures"
      :key="`featureParent ${featureParent.identifier}`"
      selectable
      :items="[featureParent]"
      :open="initiallyOpen"
      item-children="childFeatures"
      item-key="identifier"
      v-model="activeFeatureId[featureParent.identifier]"
    >
      <template v-slot:append="{ item, selected }">
        <v-switch
          class="tw-mt-0"
          v-if="item.childFeatures.length === 0"
          hide-details
          :label="$t(`switch.${selected}`)"
          :v-model="item.selected"
          :input-value="selected"
          @change="toggleItem($event, item, featureParent.identifier)"
        />
      </template>

      <template v-slot:label="{ item }">
        <div class="client-features-recursive__treeview__title" v-if="labelIsTitle(item.identifier, index)">
          {{ item.name }}
          <v-divider class="client-features-recursive__treeview__title__divider" />
        </div>
        <span v-else>{{ item.name }}</span>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { clone } from '@/utils/utilities.util'

export default {
  name: 'ClientsFeaturesRecursives',
  props: {
    features: {
      type: Array,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeFeatureId: {},
      modelFeatures: [],
    }
  },
  created() {
    this.modelFeatures = clone(this.features)
    const keys = this.features.map(feature => feature.identifier)
    keys.forEach(async (key, index) => {
      const ids = await this.recursive(this.modelFeatures[index])
      const activateFeature = ids.filter(id => this.client.features.includes(id))
      this.$set(this.activeFeatureId, key, clone(activateFeature || []))
    })
  },
  computed: {
    initiallyOpen() {
      return this.features.map(feature => feature.identifier)
    },
  },
  methods: {
    async toggleItem(e, item, arrayName) {
      if (e) {
        this.activeFeatureId[arrayName].push(item.identifier)
      } else {
        const ids = await this.getArrayIdOfChild(item)
        Object.keys(this.activeFeatureId).forEach(key => {
          this.activeFeatureId[key] = this.activeFeatureId[key].filter(i => !ids.includes(i))
        })
      }
    },
    async getArrayIdOfChild(item) {
      return item.childFeatures.length > 0 ? await this.recursive(item) : [item.identifier]
    },
    async recursive(item, ids = []) {
      if (item.childFeatures.length === 0) {
        return [...ids, item.identifier]
      }
      await Promise.all(
        item.childFeatures.map(async element => {
          ids.push(element.identifier)
          await this.recursive(element, ids)
        })
      )
      return ids
    },
    labelIsTitle(identifier, index) {
      return this.modelFeatures[index].identifier === identifier
    },
  },
}
</script>

<style lang="scss" scoped>
.client-features-recursive {
  @apply tw-h-full tw-m-8;

  &__treeview {
    box-shadow: 1px 1px 4px 1px #d7d7d7 !important;

    &__title {
      @apply tw-text-lg tw-p-4;
      box-shadow: 0px 2px 2px 0px #d7d7d7 !important;
      text-transform: uppercase;

      &__divider {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
      }
    }
  }
}
</style>
