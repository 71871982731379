<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="client-create" v-else>
      <span class="tw-text-xl tw-font-semibold tw-ml-6">{{ $t('clients.create.title') }}</span>
      <v-stepper class="client-create__stepper" elevation="0" v-model="currentStep">
        <v-stepper-header class="client-create__stepper__header">
          <v-stepper-step :complete="currentStep > 1" step="1">
            {{ $t('clients.create.step.first.label') }}
          </v-stepper-step>
          <v-divider class="tw-mx-4" />
          <v-stepper-step :complete="currentStep > 2" step="2">
            {{ $t('clients.create.step.second.label') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items class="client-create__stepper__content">
          <form class="tw-flex tw-flex-col tw-justify-center" @submit.prevent="onSubmit" novalidate autocomplete="off">
            <v-stepper-content class="sm:tw-w-2/3 tw-mx-auto" step="1">
              <span class="tw-font-semibold">{{ $t('clients.create.step.first.title') }}</span>
              <div class="client-create__stepper__content__grid">
                <ui-block id="nameLabel" class="required-asterisk" :label="$t('clients.create.form.name.label')">
                  <template v-slot:body>
                    <v-text-field
                      v-model="clientModel.name"
                      id="name"
                      solo
                      flat
                      outlined
                      dense
                      :placeholder="$t('clients.create.form.name.placeholder')"
                      :error-messages="simpleErrors('clientModel', 'name')"
                    />
                  </template>
                </ui-block>
                <ui-block
                  id="legalNameLabel"
                  class="required-asterisk"
                  :label="$t('clients.create.form.legalName.label')"
                >
                  <template v-slot:body>
                    <v-text-field
                      v-model="clientModel.legalName"
                      id="legalName"
                      solo
                      flat
                      outlined
                      dense
                      :placeholder="$t('clients.create.form.legalName.placeholder')"
                      :error-messages="simpleErrors('clientModel', 'legalName')"
                    />
                  </template>
                </ui-block>
                <ui-block
                  id="defaultLocaleIsoCodeLabel"
                  class="required-asterisk"
                  :label="$t('clients.create.form.defaultLocaleIsoCode.label')"
                >
                  <template v-slot:body>
                    <v-autocomplete
                      v-model="clientModel.defaultLocaleIsoCode"
                      id="defaultLocaleIsoCode"
                      solo
                      flat
                      outlined
                      dense
                      item-value="code"
                      item-text="name"
                      :items="locales"
                      :placeholder="$t('clients.dashboard.information.form.defaultLocaleIsoCode.placeholder')"
                      :error-messages="simpleErrors('clientModel', 'defaultLocaleIsoCode')"
                    />
                  </template>
                </ui-block>
                <ui-block
                  id="legalCountryIsoCodeLabel"
                  class="required-asterisk"
                  :label="$t('clients.create.form.legalCountryIsoCode.label')"
                >
                  <template v-slot:body>
                    <v-autocomplete
                      v-model="clientModel.legalCountryIsoCode"
                      id="legalCountryIsoCode"
                      solo
                      flat
                      outlined
                      dense
                      item-value="0"
                      item-text="1"
                      :items="Object.entries(availablesCountries)"
                      :placeholder="$t('clients.create.form.legalCountryIsoCode.placeholder')"
                      :error-messages="simpleErrors('clientModel', 'legalCountryIsoCode')"
                    />
                  </template>
                </ui-block>
                <ui-block
                  id="verticalLabel"
                  class="required-asterisk"
                  :label="$t('clients.create.form.vertical.label')"
                >
                  <template v-slot:body>
                    <v-autocomplete
                      v-model="clientModel.vertical"
                      id="vertical"
                      solo
                      flat
                      outlined
                      dense
                      item-value="identifier"
                      item-text="name"
                      :items="verticals"
                      :placeholder="$t('clients.create.form.vertical.placeholder')"
                      :error-messages="simpleErrors('clientModel', 'vertical')"
                    />
                  </template>
                </ui-block>
                <ui-block id="categoryLabel" :label="$t('clients.create.form.category.label')">
                  <template v-slot:body>
                    <v-autocomplete
                      v-model="clientModel.category"
                      id="category"
                      solo
                      flat
                      outlined
                      dense
                      item-value="value"
                      item-text="label"
                      :items="categories"
                      :placeholder="$t('clients.create.form.category.placeholder')"
                    />
                  </template>
                </ui-block>
                <ui-block id="typeLabel" class="required-asterisk" :label="$t('clients.create.form.type.label')">
                  <template v-slot:body>
                    <v-autocomplete
                      v-model="clientModel.type"
                      id="type"
                      solo
                      flat
                      outlined
                      dense
                      item-value="identifier"
                      item-text="name"
                      :items="types"
                      :placeholder="$t('clients.create.form.type.placeholder')"
                      :error-messages="simpleErrors('clientModel', 'type')"
                    />
                  </template>
                </ui-block>
                <ui-block id="activeLabel" :label="$t('clients.create.form.active.label')">
                  <template v-slot:body>
                    <v-switch id="active" class="tw-mt-0" v-model="clientModel.active" />
                  </template>
                </ui-block>
              </div>
              <div class="tw-my-8 tw-flex tw-flex-row tw-justify-between">
                <v-btn :to="{ name: 'ClientsList' }" outlined class="tw-px-8" color="#c5c5c5">
                  {{ $t('button.cancel') }}
                </v-btn>
                <v-btn @click="checkIsClientValid" class="tw-px-8" color="#449afd" dark>
                  {{ $t('button.continue') }}
                </v-btn>
              </div>
            </v-stepper-content>
            <v-stepper-content class="tw-mx-auto" step="2">
              <span class="tw-font-semibold">{{ $t('clients.create.step.second.title') }}</span>
              <clients-features-recursive
                class="tw-mt-8"
                ref="features"
                :features="features"
                :client="{ features: [] }"
              />
              <div class="tw-mt-10 tw-mb-8 tw-flex tw-flex-row tw-justify-between">
                <v-btn @click="currentStep -= 1" outlined class="tw-px-8" color="#c5c5c5">
                  {{ $t('button.prev') }}
                </v-btn>
                <v-btn :loading="saving" type="submit" class="tw-px-8" color="#449afd" dark>
                  {{ $t('button.save') }}
                </v-btn>
              </div>
            </v-stepper-content>
          </form>
        </v-stepper-items>
      </v-stepper>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { union } from 'lodash'
import { mdiSortCalendarDescending, mdiSortCalendarAscending } from '@mdi/js'
import ErrorsMixin from '@/mixins/errors.mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import UiBlock from '@/components/UI/Block.vue'
import ClientsFeaturesRecursive from '@/components/Clients/Features/Recursive.vue'

export default {
  name: 'ClientCreate',
  components: {
    UiBlock,
    ClientsFeaturesRecursive,
  },
  mixins: [validationMixin, ErrorsMixin],
  data() {
    return {
      icons: {
        mdiSortCalendarDescending,
        mdiSortCalendarAscending,
      },
      saving: false,
      clientModel: {
        name: null,
        legalName: null,
        defaultLocaleIsoCode: 'fr-fr',
        legalCountryIsoCode: 'FR',
        vertical: null,
        features: [],
        category: null,
        type: null,
        visibility: false,
        active: false,
      },
      currentStep: 1,
    }
  },
  watch: {
    'clientModel.name'() {
      this.clientModel.legalName = this.clientModel.name
    },
  },
  async created() {
    this.setUpdating(true)
    await Promise.all([this.getAuthentication(), this.getVerticals(), this.getTypes(), this.getFeatures()])
    this.initializePresetForm()
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      currentClient: state => state.client.currentClient,

      availablesCountries: state => state.gmb.availablesCountries,

      locales: state => state.backoffice.locales,
      updating: state => state.backoffice.updating,

      verticals: state => state.setting.verticals,
      types: state => state.setting.types,
      features: state => state.setting.features,
    }),
    categories() {
      return [
        {
          label: this.$t('clients.create.form.category.options.client'),
          value: 'client',
        },
        {
          label: this.$t('clients.create.form.category.options.prospect'),
          value: 'prospect',
        },
        {
          label: this.$t('clients.create.form.category.options.internal'),
          value: 'internal',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      createClient: 'client/createClient',

      getAuthentication: 'backoffice/getAuthentication',
      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',

      getTypes: 'setting/getTypes',
      getVerticals: 'setting/getVerticals',
      getFeatures: 'setting/getFeatures',
    }),
    initializePresetForm() {
      this.clientModel.vertical = this.verticals[0].identifier
      this.clientModel.type = this.types[0].identifier
    },
    async checkIsClientValid() {
      const errors = await this.fieldsHaveErrors('clientModel', [
        'name',
        'legalName',
        'defaultLocaleIsoCode',
        'legalCountryIsoCode',
        'vertical',
        'type',
      ])
      if (!errors) {
        this.currentStep += 1
      }
    },
    async onSubmit() {
      this.saving = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const mergeArray = union.apply(false, Object.values(this.$refs.features.activeFeatureId))
          const clientCreate = {
            ...this.clientModel,
            features: mergeArray,
          }

          await this.createClient(clientCreate)
          this.showNotificationSuccess()
          this.$router.push({ name: 'ClientsDashboard', params: { id: this.currentClient.uuid } })
        } catch {
          this.showNotificationError()
        }
        this.saving = false
      }
    },
    showNotificationError() {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.default'),
      })
      this.saving = false
    },
    showNotificationSuccess() {
      this.setAlert({
        color: 'success',
        text: this.$t('notification.create.client', { name: this.currentClient.name }),
      })
      this.saving = false
    },
  },
  validations() {
    return {
      clientModel: {
        name: {
          required,
        },
        legalName: {
          required,
        },
        defaultLocaleIsoCode: {
          required,
        },
        legalCountryIsoCode: {
          required,
        },
        vertical: {
          required,
        },
        type: {
          required,
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.client-create {
  @apply tw-h-full tw-mx-0 tw-my-4 sm:tw-mx-8 sm:tw-my-8;

  &__stepper {
    @apply tw-my-0 sm:tw-my-4;
    background-color: inherit !important;

    &__header {
      box-shadow: none !important;
    }

    &__content {
      @apply tw-mx-0 tw-mt-0 sm:tw-mt-6 sm:tw-mx-4;
      background-color: $white;

      &__grid {
        @apply tw-mx-auto tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full;
      }
    }
  }
}
</style>
